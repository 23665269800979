import React from "react";
import Image from "./Image";

export default class Box extends React.Component {
  toggleItem(item) {
    const index = this.props.hats.findIndex((d) => d === item);

    const hats = this.props.hats.filter((_, i) => i !== index);

    this.props.toggleItem(hats);
  }

  render() {
    const grouped = (this.props.hats || []).reduce((obj, curr) => {
      if (!obj[curr]) {
        obj[curr] = 0;
      }

      obj[curr]++;

      return obj;
    }, {});
    return (
      <div className="box">
        <div className="boxTitle">
          <div className="text" style={{ color: this.props.titleColor }}>
            {this.props.title}
          </div>
          <input
            className="nameEdit"
            defaultValue={this.props.title}
            onBlur={(e) => this.props.updateTitle(e.target.value)}
          />
          <button className="deleteButton" onClick={this.props.deleteBox}>
            Slet
          </button>
        </div>

        <div
          className="selectedHats"
          style={{
            gridTemplateColumns: `repeat(${this.props.rowSize}, 1fr)`,
            maxWidth: `${this.props.boxSize}px`,
            backgroundColor: this.props.boxColor,
          }}
        >
          {Object.keys(grouped).map((item) => {
            const amount = grouped[item];
            const hat = this.props.data.find((d) => d?.id === item);
            return (
              hat && (
                <div
                  className="hat"
                  onClick={() => this.toggleItem(item)}
                  key={item}
                >
                  <Image id={hat.id} zoom={this.props.imageZoom / 100} />
                  {amount > 1 && <div className="amount">x{amount}</div>}
                  {/* <img src={`https://hp3.clonex.dk/api.php?type=img&value=${hat.id}`}/> */}
                  {/* {
                                        this.state.showNames && <div className="title">{hat.name}</div>
                                    } */}
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }
}
